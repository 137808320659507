/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.separatorWrap {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden; }
  .separatorWrap .separator {
    color: #767676;
    letter-spacing: 0.2px;
    font-size: 15px;
    line-height: 18px;
    padding: 16px;
    position: relative;
    display: inline-block; }
    .separatorWrap .separator:before {
      content: '';
      position: absolute;
      border-bottom: 1px solid #eeeeee;
      top: 50%;
      right: 100%;
      width: 5000px; }
    .separatorWrap .separator:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid #eeeeee;
      top: 50%;
      left: 100%;
      width: 5000px; }
