.align {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .align.v-top {
    align-items: flex-start; }
  .align.v-center {
    align-items: center; }
  .align.v-bottom {
    align-items: flex-end; }
  .align.v-stretch {
    align-items: stretch; }
  .align.h-start {
    justify-content: flex-start; }
  .align.h-center {
    justify-content: center; }
  .align.h-end {
    justify-content: flex-end; }
  .align.h-between {
    justify-content: space-between; }
  .align.h-around {
    justify-content: space-around; }
  .align.h-evenly {
    justify-content: space-evenly; }
