/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-family: DM Sans, sans-serif;
  position: relative;
  background: #ff4959;
  padding: 12px 24px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: none;
  white-space: nowrap;
  outline: 0; }
  .button:focus {
    outline: 0; }
  .button svg {
    fill: #fff;
    margin: 0 0 0 5px; }
  .button.solidLight {
    background: #fff;
    color: #ff4959;
    border: 1px solid #fff; }
    .button.solidLight:hover {
      background: transparent;
      color: #fff; }
  .button.outlineLight {
    background: transparent;
    border: 1px solid #fff; }
    .button.outlineLight:hover {
      background: #fff;
      color: #ff4959; }
  .button:disabled {
    background: rgba(255, 73, 89, 0.5);
    cursor: default; }
    .button:disabled:hover {
      background: rgba(255, 73, 89, 0.5);
      box-shadow: none; }
  .button:hover {
    background: #e54150;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
  .button.extend {
    width: 100%; }
  .button.enlarge {
    padding: 16px 30px; }
  .button.loading {
    color: rgba(255, 255, 255, 0.75); }
