.component {
  text-align: center; }
  .component .imageWrap {
    position: relative;
    max-width: 200px;
    margin: 50px auto 0; }
    .component .imageWrap img {
      display: block;
      margin: 0 auto; }
    .component .imageWrap .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      position: absolute;
      right: -30px;
      bottom: -30px; }
  .component h3 {
    padding: 40px 0 10px; }
  .component p {
    margin: 0 auto 40px !important;
    max-width: 400px; }
  .component button {
    margin: 0 auto; }
