/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.wrapper {
  display: block; }
  .wrapper .paypalMountWrap {
    position: relative;
    z-index: 0; }
    .wrapper .paypalMountWrap .loader {
      width: 100%;
      height: 45px;
      position: absolute;
      background: #fff;
      border: 1px solid #767676;
      z-index: 199;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .wrapper .paypalMountWrap .paypalMount {
      display: block;
      position: relative; }
  .wrapper .error {
    margin: 5px 0 0 3px;
    font-size: 15px;
    color: #d43900; }

.loading h6 {
  margin: 0 0 20px; }
