.item {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee; }
  .item .iHead {
    cursor: pointer;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    transition: background 0.2s; }
    .item .iHead:hover {
      background: #f9f9f9; }
    .item .iHead .iTitle {
      color: #222; }
  .item .iBody {
    padding: 15px 30px 30px;
    font-size: 14px; }
    .item .iBody p,
    .item .iBody ul li {
      color: #666; }
