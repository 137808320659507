/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
.component {
  padding: 80px 0 40px;
  max-width: 750px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .component {
      padding: 40px 0; } }
  .component .listWrap {
    display: flex;
    flex-direction: column;
    overflow: scroll; }
    .component .listWrap .listHead {
      display: flex;
      align-items: center;
      padding: 25px 0; }
      .component .listWrap .listHead .date {
        width: 150px;
        max-width: 100%;
        font-weight: 700; }
      .component .listWrap .listHead .quantity {
        width: 150px;
        max-width: 100%;
        font-weight: 700; }
    .component .listWrap .list {
      display: flex;
      flex-direction: column; }
      .component .listWrap .list .item {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eee;
        padding: 15px 0; }
        .component .listWrap .list .item .left {
          display: flex;
          align-items: center; }
          .component .listWrap .list .item .left .date {
            width: 150px;
            max-width: 100%; }
          .component .listWrap .list .item .left .quantity {
            width: 150px;
            max-width: 100%; }
