/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component h1 {
  text-align: center;
  margin: 25px 0 30px; }

.component .description {
  margin: -10px 0 30px;
  text-align: center; }

.component .globalError {
  margin: 0 0 20px; }

.component .remember {
  display: flex;
  margin: 0 0 7px; }

.component .callout {
  text-align: center;
  font-size: 16px;
  padding: 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .component .callout a,
  .component .callout .signupLink {
    cursor: pointer;
    font-weight: 700;
    color: #ff4959;
    padding: 0 0 0 5px; }

.component .forgotPassword {
  text-align: center;
  padding: 10px 0 0;
  font-size: 14px; }
  .component .forgotPassword a {
    color: #222222; }
