/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.table {
  display: block;
  overflow: scroll; }
  .table::-webkit-scrollbar {
    -webkit-appearance: none; }
  .table::-webkit-scrollbar:vertical {
    width: 8px; }
  .table::-webkit-scrollbar:horizontal {
    height: 8px; }
  .table::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    /* should match background, can't be transparent */
    background-color: #dddddd; }
  .table .tableHead,
  .table .tableItem {
    display: flex;
    align-items: center;
    padding: 25px 0; }
    .table .tableHead span,
    .table .tableItem span {
      min-width: 150px;
      padding: 0 10px; }
      .table .tableHead span:first-child,
      .table .tableItem span:first-child {
        padding-left: 0; }
      .table .tableHead span:last-child,
      .table .tableItem span:last-child {
        padding-right: 0; }
  .table .tableHead {
    font-weight: 700;
    padding: 25px 0; }
  .table .tableItem {
    border-top: 1px solid #eeeeee; }
