/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  display: block; }
  .component h1 {
    text-align: center;
    margin: 25px 0 30px; }
  .component .description {
    margin: -10px 0 30px;
    text-align: center; }
  .component .globalError {
    margin: 0 0 20px; }
  .component .remember {
    display: flex;
    margin: 0 0 16px; }
  .component .callout {
    text-align: left;
    font-size: 13px;
    padding: 5px 0 22px; }
    .component .callout a {
      font-weight: 700;
      color: #ff4959; }

.haveAccount {
  text-align: center;
  padding: 20px 0 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .haveAccount a,
  .haveAccount .loginLink {
    cursor: pointer;
    color: #222222;
    margin: 0 0 0 5px; }
