/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0; }
  .checkbox input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none; }
    .checkbox input[type='checkbox']:checked + .check {
      border-color: #ff4959;
      background: #ff4959;
      box-shadow: 0 0 8px #dddddd; }
      .checkbox input[type='checkbox']:checked + .check svg {
        display: block; }
    .checkbox input[type='checkbox']:focus + .check {
      border-color: #ff4959;
      box-shadow: 0 0 8px #dddddd; }
  .checkbox .check {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    overflow: hidden; }
    .checkbox .check svg {
      display: none;
      fill: #fff; }
  .checkbox .text {
    padding-left: 8px;
    font-size: 15px; }
