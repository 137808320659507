/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

.inputWrap {
  margin: 0 0 16px; }
  .inputWrap label {
    position: relative;
    padding: 0 10px;
    color: #717171;
    width: 100%;
    text-align: left;
    font-size: 13px;
    margin: 0 0 3px; }
  .inputWrap input {
    border-radius: 4px;
    border: 1px solid #ddd;
    transition: all 0.2s ease; }
    .inputWrap input:focus {
      border-color: #767676; }
  .inputWrap.miniLabel label {
    transform: translate(8px, -10px) scale(0.8);
    color: #484848; }
  .inputWrap.miniLabel fieldset legend {
    max-width: 1000px; }
  .inputWrap.error .field {
    border: 1px solid #d43900;
    background: #fff6f6; }
  .inputWrap.error .error {
    color: #d43900;
    padding: 8px 8px 0;
    font-size: 14px; }
  .inputWrap.hasIcon .field input {
    padding-right: 45px; }
  .inputWrap.small {
    margin: 0 0 8px; }
    .inputWrap.small .field input {
      padding: 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400; }
