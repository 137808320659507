.pricingPage {
  display: block;
  padding: 60px 0 0;
  position: relative; }
  .pricingPage .stepper {
    position: relative;
    max-width: 500px;
    margin: 0 auto; }
    .pricingPage .stepper .border {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 2px;
      content: '';
      display: block;
      background: linear-gradient(to left, rgba(20, 52, 91, 0), #000 50%, rgba(45, 100, 148, 0)); }
    .pricingPage .stepper .steps {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px; }
      .pricingPage .stepper .steps .step {
        position: relative; }
        .pricingPage .stepper .steps .step .round {
          margin: 0 25px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background: #ff4959;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 700; }
        .pricingPage .stepper .steps .step .text {
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          top: 100%;
          margin: 5px 0 0;
          font-size: 11px;
          color: #999;
          font-style: italic; }
        .pricingPage .stepper .steps .step.active .round {
          box-shadow: 0 0 20px 1px #ff4959; }
  .pricingPage .heroImage {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 500px; }
  .pricingPage .info {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    padding: 25px 0 50px;
    background: #fff;
    border-radius: 10px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .pricingPage .info {
        padding: 0; } }
    .pricingPage .info p {
      margin: 20px auto 0; }
