/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  display: block;
  margin: 80px auto; }
  .component .head {
    text-align: center;
    max-width: 550px;
    margin: 0 auto 100px; }
    .component .head h6 {
      color: #ff4959;
      margin: 0 0 10px; }
    .component .head h1 {
      margin: 0 0 20px; }
  .component .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 900px; }
    @media screen and (max-width: 768px) {
      .component .row {
        flex-direction: column; } }
    .component .row.middle {
      margin: 120px auto 100px; }
      @media screen and (max-width: 768px) {
        .component .row.middle {
          flex-direction: column-reverse; } }
    .component .row.last {
      margin: 0 auto 80px; }
    .component .row .col {
      margin: 0 20px;
      width: calc(50% - 40px);
      max-width: 400px; }
      @media screen and (max-width: 768px) {
        .component .row .col {
          width: 100%;
          margin: 0;
          max-width: 100%;
          text-align: center; } }
      .component .row .col h6 {
        color: #ff4959;
        margin: 0 0 15px; }
      .component .row .col h3 {
        margin: 0 0 15px; }
      .component .row .col p {
        font-size: 14px;
        color: #716c80; }
      .component .row .col img {
        max-width: 100%; }
        @media screen and (max-width: 768px) {
          .component .row .col img {
            margin: 40px auto 0; } }
