/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  max-width: 500px;
  margin: 0 auto;
  position: relative; }
  .component .top {
    padding: 30px 0 50px; }
    .component .top h6 {
      color: #ff4959;
      margin: 0 0 5px; }
    .component .top p {
      max-width: 400px;
      margin: 10px auto 0; }
  .component .packages {
    position: relative; }
    .component .packages .head {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      font-weight: 500;
      font-size: 13px; }
      @media screen and (max-width: 768px) {
        .component .packages .head {
          padding: 10px 0; } }
      .component .packages .head .quantity {
        width: 150px;
        text-align: left; }
        @media screen and (max-width: 768px) {
          .component .packages .head .quantity {
            width: 100px; } }
      .component .packages .head .price {
        width: 150px;
        text-align: left; }
        @media screen and (max-width: 768px) {
          .component .packages .head .price {
            width: 100px; } }
