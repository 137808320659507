/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
.notFoundWrap {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .notFoundWrap .notFoundInner {
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 80px; }
    @media (max-width: 768px) {
      .notFoundWrap .notFoundInner {
        flex-direction: column; } }
    .notFoundWrap .notFoundInner .imageWrap {
      width: 100%;
      max-width: 400px;
      padding: 0 30px 0 0; }
      @media (max-width: 768px) {
        .notFoundWrap .notFoundInner .imageWrap {
          padding: 0; } }
      .notFoundWrap .notFoundInner .imageWrap img {
        width: 100%; }
    @media (max-width: 768px) {
      .notFoundWrap .notFoundInner .contentWrap {
        padding: 40px 0 0;
        text-align: center; } }
    .notFoundWrap .notFoundInner .contentWrap h1 {
      font-size: 80px;
      margin: 0 0 30px; }
    .notFoundWrap .notFoundInner .contentWrap p {
      margin: 25px 0;
      max-width: 450px; }
