/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  max-width: 500px;
  margin: 0 auto;
  position: relative; }
  .component .top {
    padding: 30px 0 50px; }
    .component .top h6 {
      color: #ff4959;
      margin: 0 0 5px; }
    .component .top p {
      max-width: 400px;
      margin: 10px auto 0; }
    .component .top .change {
      color: #ff4959;
      cursor: pointer;
      transition: color 0.2s; }
      .component .top .change:hover {
        color: #111; }
  .component .message {
    padding: 15px 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    max-width: 350px;
    margin: 0 auto; }
    .component .message span {
      font-size: 12px; }
