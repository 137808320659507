.component {
  display: block; }
  .component .invalidToken,
  .component .resetSuccess {
    text-align: center; }
    .component .invalidToken h4,
    .component .resetSuccess h4 {
      margin: 25px 0 20px; }
    .component .invalidToken p,
    .component .resetSuccess p {
      margin: 0; }
  .component form h1 {
    margin: 25px 0 30px;
    text-align: center; }
