.link {
  display: flex;
  align-items: center;
  text-decoration: none !important; }
  .link svg {
    position: relative;
    top: -1px; }
  .link.themeSolid .text {
    color: #484848; }
  .link .text {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0 0 0 20px;
    position: relative;
    text-decoration: none; }
    .link .text span {
      font-size: 9px;
      position: absolute;
      top: -5px;
      right: -20px;
      letter-spacing: 1px; }
