.package {
  padding: 12px 15px;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  transition: all 0.2s ease;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .package {
      padding: 12px 0; } }
  .package:hover {
    background: #f9f9f9; }
    .package:hover .buttonWrap button {
      background: #e54150; }
  .package .left {
    display: flex;
    align-items: center; }
    .package .left .quantity {
      width: 150px;
      text-align: left; }
      @media screen and (max-width: 768px) {
        .package .left .quantity {
          width: 100px;
          font-size: 14px; } }
    .package .left .price {
      display: flex;
      width: 150px; }
      @media screen and (max-width: 768px) {
        .package .left .price {
          width: 100px; } }
      .package .left .price .dollar {
        font-size: 12px;
        margin: 0 3px 0 0; }
      .package .left .price .number {
        font-size: 18px;
        font-weight: 700;
        font-size: 16px; }
  .package .buttonWrap button {
    border-radius: 100px;
    padding: 6px 12px;
    color: #fff;
    background: #ff4959;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: all 0.2s ease; }
    @media screen and (max-width: 768px) {
      .package .buttonWrap button {
        font-size: 12px; } }
