/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

.menuToggle {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 20;
  outline: 0 !important; }
  .menuToggle .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 19px;
    height: 19px; }
    .menuToggle .inner:before {
      background: #fff;
      margin-top: -4px; }
    .menuToggle .inner:after {
      background: #fff;
      margin-top: 4px; }
    .menuToggle .inner:before, .menuToggle .inner:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      top: calc(50% - 1px);
      right: 0;
      transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1); }
  .menuToggle.solid .inner:before, .menuToggle.solid .inner:after {
    background: #111; }
  .menuToggle.active .inner:before {
    transform: rotate(135deg);
    margin: 0;
    background: #111; }
  .menuToggle.active .inner:after {
    transform: rotate(-135deg);
    margin: 0;
    background: #111; }
