/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.modal {
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); }
  .modal .body {
    position: relative;
    width: 450px;
    max-width: 100%;
    background: #fff;
    padding: 50px 40px 60px;
    border-radius: 5px;
    box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.05);
    max-height: 80vh;
    overflow: scroll; }
    @media (max-width: 768px) {
      .modal .body {
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; } }
    .modal .body .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      transform: scale(1);
      transition: transform 0.2s ease; }
      .modal .body .close:hover {
        transform: scale(1.1); }
    .modal .body .bodyInner {
      position: relative; }

.enter .overlay,
.enter .body {
  opacity: 0; }

@media (max-width: 768px) {
  .enter .body {
    opacity: 1;
    transform: translateY(100%); } }

.enter.enterActive .overlay,
.enter.enterActive .body {
  opacity: 1;
  transition: opacity 0.2s ease-out; }

@media (max-width: 768px) {
  .enter.enterActive .body {
    transform: translateY(0);
    transition: transform 0.3s ease-out; } }

.exit .overlay,
.exit .body {
  opacity: 1; }

@media (max-width: 768px) {
  .exit .body {
    transform: translateY(0); } }

.exit.exitActive .overlay,
.exit.exitActive .body {
  opacity: 0;
  transition: opacity 0.2s ease-out; }

@media (max-width: 768px) {
  .exit.exitActive .body {
    opacity: 1;
    transform: translateY(100%);
    transition: transform 0.3s ease-out; } }
