/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  padding: 25px 0;
  border-bottom: 1px solid #eeeeee; }
  .component .head {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .component .head.spacer {
      margin: 0 0 25px; }
    .component .head .title {
      font-weight: 500; }
    .component .head .action {
      color: #ff4959; }
      .component .head .action:hover {
        text-decoration: underline; }
  .component .description {
    margin: 10px 0 0; }
