/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
.footer {
  position: relative;
  background-size: cover !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  width: 100%; }
  .footer .footerInner {
    display: flex;
    justify-content: space-between;
    padding: 300px 0 0;
    margin: 0 0 0 auto;
    max-width: 750px; }
    @media screen and (max-width: 992px) {
      .footer .footerInner {
        flex-direction: column-reverse;
        padding: 300px 0 0; } }
    @media screen and (max-width: 768px) {
      .footer .footerInner {
        flex-direction: column-reverse;
        padding: 250px 0 0; } }
    .footer .footerInner .left {
      display: flex;
      min-width: 500px; }
      @media screen and (max-width: 992px) {
        .footer .footerInner .left {
          min-width: initial;
          flex-direction: column-reverse; } }
    .footer .footerInner .right {
      width: 100%; }
      @media screen and (max-width: 992px) {
        .footer .footerInner .right {
          padding: 50px 0 30px; } }
      .footer .footerInner .right p {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.75);
        margin: 20px 0; }
    .footer .footerInner .col {
      width: 33.33333%;
      margin: 0 0 25px;
      padding: 0 0 20px; }
      @media screen and (max-width: 992px) {
        .footer .footerInner .col {
          flex-direction: column;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          width: 100%; } }
      .footer .footerInner .col .title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        color: rgba(255, 255, 255, 0.75); }
      .footer .footerInner .col ul {
        list-style: none;
        margin: 0;
        padding: 10px 0 0;
        margin: 0 -10px; }
        @media (max-width: 992px) {
          .footer .footerInner .col ul {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between; } }
        .footer .footerInner .col ul a {
          color: #fff;
          padding: 5px 10px;
          min-width: 150px;
          display: flex;
          font-size: 12px; }
          @media (max-width: 992px) {
            .footer .footerInner .col ul a {
              flex-basis: 33.3333% !important; } }
          .footer .footerInner .col ul a li {
            padding: 5px 0;
            font-size: 13px; }
  .footer .copyright {
    padding: 20px 0 75px;
    max-width: 750px;
    margin: 0 0 0 auto; }
    @media (max-width: 768px) {
      .footer .copyright {
        padding: 20px 0 55px; } }
    .footer .copyright .text {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.75); }
    .footer .copyright .links {
      display: flex;
      align-items: center;
      margin: 10px -15px 0; }
      .footer .copyright .links a {
        margin: 0 15px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.75);
        transition: color 0.2s ease; }
        .footer .copyright .links a:hover {
          color: #fff;
          text-decoration: none; }
