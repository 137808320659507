.privacyPage {
  display: block; }
  .privacyPage .privacyPageInner {
    padding: 60px 0 40px;
    max-width: 700px;
    margin: 0 auto; }
    .privacyPage .privacyPageInner h1 {
      margin: 0 0 30px; }
    .privacyPage .privacyPageInner h4 {
      margin: 40px 0 20px; }
    .privacyPage .privacyPageInner ol {
      margin: 25px 0;
      list-style-type: lower-roman; }
