/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

.mobileMenu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  background: #fff;
  padding: 40px;
  overflow-y: scroll; }
  .mobileMenu .logo {
    position: relative;
    padding: 0 0 40px; }
  .mobileMenu .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0; }
    .mobileMenu .nav .divider {
      height: 1px;
      width: 100%;
      background: #dddddd;
      margin: 20px 0; }
    .mobileMenu .nav .navItem {
      display: flex;
      align-items: stretch;
      text-decoration: none;
      margin: 6px 0;
      height: auto; }
      .mobileMenu .nav .navItem button {
        position: relative;
        top: -2px; }
      .mobileMenu .nav .navItem .link {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 2px solid transparent;
        line-height: 20px;
        color: #484848;
        cursor: pointer;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 400; }
      .mobileMenu .nav .navItem:hover a,
      .mobileMenu .nav .navItem:hover .link {
        border-bottom-color: #222222; }
