.component {
  padding: 80px 0 40px;
  max-width: 750px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .component {
      padding: 40px 0; } }
  .component .last {
    width: 100%;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
