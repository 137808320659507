.component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center; }
  .component h3 {
    margin: 25px 0 0; }
  .component p {
    margin: 20px 0; }
