/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.globalError {
  padding: 20px;
  border-top: 3px solid #d43900;
  display: flex;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 #333333; }
  .globalError svg {
    fill: #d43900; }
  .globalError .message {
    margin: 0 0 0 20px;
    color: colorText(base);
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden; }
