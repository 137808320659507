/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
.component {
  margin: 40px auto 0;
  max-width: 100%;
  width: 450px;
  max-width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background: #fff;
  padding: 40px; }
  @media (max-width: 768px) {
    .component {
      border: 0;
      padding: 25px 0; } }
