/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.component {
  padding: 50px 0 0;
  position: relative; }
  .component .inner {
    position: relative;
    z-index: 2; }
    .component .inner .left {
      max-width: 500px;
      margin: 0 auto;
      text-align: center; }
      .component .inner .left h6 {
        color: #ff4959;
        margin: 0 0 15px; }
      .component .inner .left h2 {
        margin: 0 0 15px; }
      .component .inner .left p {
        font-size: 14px;
        color: #716c80;
        max-width: 400px;
        margin: 0 auto 10px; }
      .component .inner .left .action {
        margin: 25px 0 0;
        position: relative;
        display: inline-block; }
        .component .inner .left .action button {
          z-index: 1;
          height: 70px;
          padding: 12px 50px; }
        .component .inner .left .action .dots {
          position: absolute;
          top: -15px;
          right: -15px;
          max-width: 175px; }
  .component .dottedMap {
    max-width: 800px;
    position: absolute;
    bottom: -150px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1; }
