/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

.emailConfirmed .loading {
  display: flex; }

.emailConfirmed .emailSent {
  display: flex;
  align-items: center; }
  .emailConfirmed .emailSent svg {
    margin: 0 10px 0 0;
    fill: #ff4959; }

.emailConfirmed .emailLink {
  color: #ff4959;
  font-weight: 700;
  transition: color 0.2s, opacity 0.2s; }
  .emailConfirmed .emailLink:hover {
    opacity: 0.8; }
