.component {
  display: block;
  margin: 80px auto 60px; }
  .component h6 {
    text-align: center;
    color: #ff4959;
    margin: 0 0 10px; }
  .component h1 {
    text-align: center;
    margin: 0 0 50px; }
  .component .aboutSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0; }
    @media screen and (max-width: 992px) {
      .component .aboutSection {
        flex-direction: column;
        max-width: 500px;
        margin: 0 auto; } }
    .component .aboutSection .left {
      width: 50%; }
      @media screen and (max-width: 992px) {
        .component .aboutSection .left {
          width: 100%; } }
      .component .aboutSection .left img {
        max-width: 100%; }
    .component .aboutSection .right {
      width: 50%;
      padding: 0 0 0 40px; }
      @media screen and (max-width: 992px) {
        .component .aboutSection .right {
          padding: 60px 0 0;
          width: 100%; } }
