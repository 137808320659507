/**
 * Small tablets and large smartphones (landscape view)
 */
/**
 * Small tablets (portrait view)
 */
/**
 * Tablets and small desktops
 */
/**
 * Large tablets and desktops
 */
/**
 * Custom devices
 */
/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.header {
  background: transparent;
  position: absolute;
  width: 100%;
  z-index: 100;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25); }
  @media screen and (max-width: 992px) {
    .header {
      border-bottom: 0; } }
  .header.isAbsolute {
    box-shadow: none; }
  .header.greyBg {
    background: #fafafa; }
  .header.themeSolid {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative; }
    .header.themeSolid .inner .actions a {
      color: #484848;
      transition: color 0.2s; }
      .header.themeSolid .inner .actions a:hover {
        color: #ff4959; }
    .header.themeSolid .inner .actions .buttonLink {
      background: #ff4959; }
      .header.themeSolid .inner .actions .buttonLink:hover {
        background: #e54150; }
      .header.themeSolid .inner .actions .buttonLink a {
        color: #fff; }
  .header .inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 992px) {
      .header .inner {
        padding: 10px 0; } }
    .header .inner .logo {
      position: relative;
      z-index: 11;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .header .inner .desktopMenu {
      display: block;
      height: 80px; }
      @media (max-width: 992px) {
        .header .inner .desktopMenu {
          display: none; } }
    .header .inner .actions {
      display: flex; }
      @media screen and (max-width: 992px) {
        .header .inner .actions {
          display: none; } }
      .header .inner .actions a {
        color: #fff;
        font-size: 14px;
        padding: 10px 15px;
        text-decoration: none; }
      .header .inner .actions .buttonLink {
        background: rgba(255, 255, 255, 0.25);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 20px;
        transition: background 0.2s ease;
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05); }
        .header .inner .actions .buttonLink:hover {
          background: rgba(255, 255, 255, 0.15); }
  .header .mobileMenu {
    display: none; }
    @media (max-width: 992px) {
      .header .mobileMenu {
        display: flex; } }

.menuEnter {
  transform: translateY(-100%); }
  .menuEnter.menuEnterActive {
    transform: translateY(0);
    transition: transform 0.2s ease-out; }

.menuExit {
  transform: translateY(0); }
  .menuExit.menuExitActive {
    transform: translateY(-100%);
    transition: transform 0.2s ease-out; }
