/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

ul.nav {
  display: flex;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%; }
  ul.nav.themeSolid .navItem:hover a,
  ul.nav.themeSolid .navItem:hover .link,
  ul.nav.themeSolid .navItem.active a,
  ul.nav.themeSolid .navItem.active .link {
    border-bottom-color: #484848; }
  ul.nav.themeSolid .navItem .link {
    color: #484848; }
  ul.nav .navItem {
    display: flex;
    align-items: stretch;
    text-decoration: none;
    height: 100%; }
    ul.nav .navItem .link {
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin: 0;
      border-bottom: 1px solid transparent;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      cursor: pointer;
      transition: all 0.2s ease; }
    ul.nav .navItem:hover a,
    ul.nav .navItem:hover .link,
    ul.nav .navItem.active a,
    ul.nav .navItem.active .link {
      border-bottom-color: #fff; }
