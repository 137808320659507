.component {
  display: block;
  max-width: 750px;
  margin: 80px auto 40px; }
  .component h6 {
    text-align: center;
    color: #ff4959;
    margin: 0 0 10px; }
  .component h1 {
    text-align: center;
    margin: 0 0 50px; }
