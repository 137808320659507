/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.v2Loader {
  position: relative;
  display: inline-block;
  animation: loader 1.4s linear infinite; }
  .v2Loader svg {
    display: block; }
    .v2Loader svg circle {
      animation: loaderCircle 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: currentColor; }

@keyframes loader {
  100% {
    transform: rotate(360deg); } }

@keyframes loaderCircle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px; }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px; } }

.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .loading.button {
    padding: 8px 0; }
  .loading.primary span {
    background: #ff4959; }
  .loading.secondary span {
    background: #0e0e0e; }
  .loading.medium span {
    width: 10px;
    height: 10px;
    margin-right: 7px; }
  .loading.alignLeft {
    justify-content: flex-start; }
  .loading.alignRight {
    justify-content: flex-end; }
  .loading span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 100%;
    animation-name: loading;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;
    background: #fff; }
    .loading span:nth-child(1) {
      animation-delay: -0.3s; }
    .loading span:nth-child(2) {
      animation-delay: -0.15s; }

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0; }
  30%,
  50% {
    opacity: 1; } }
