/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.wrapper h6 {
  text-align: center;
  color: #ff4959;
  margin: 0 0 10px; }

.wrapper h1 {
  text-align: center;
  margin: 0 0 20px; }

.wrapper p {
  max-width: 500px;
  margin: 0 auto 50px;
  text-align: center; }

.wrapper.success h6 {
  margin: 30px 0 10px; }

.wrapper .component {
  display: block;
  max-width: 600px;
  margin: 0 auto; }
  .wrapper .component .row {
    display: flex;
    margin: 0 -10px; }
    .wrapper .component .row .col {
      width: calc(50% - 20px);
      margin: 0 10px; }
  .wrapper .component .message {
    margin: 0 0 30px; }
    .wrapper .component .message label {
      position: relative;
      padding: 0 10px;
      color: #717171;
      width: 100%;
      text-align: left;
      font-size: 13px;
      margin: 0 0 3px; }
    .wrapper .component .message textarea {
      width: 100%;
      height: 200px;
      border-radius: 4px;
      padding: 20px;
      border: 1px solid #ddd;
      transition: all 0.2s ease;
      outline: 0; }
      .wrapper .component .message textarea.hasError {
        border: 1px solid #d43900;
        background: #fff6f6; }
      .wrapper .component .message textarea:focus {
        border-color: #767676; }
    .wrapper .component .message .error {
      color: #d43900;
      padding: 8px 8px 0;
      font-size: 14px; }
