/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Speeds
     */
  durationFaster: 0.05s;
  durationFast: 0.1s;
  durationMedium: 0.15s;
  durationRegular: 0.2s;
  durationSlow: 0.3s;
  durationSlower: 0.4s; }

.stripeForm {
  position: relative; }
  .stripeForm .loading h6 {
    margin: 0 0 20px; }
  .stripeForm .paymentForm {
    display: flex;
    flex-direction: column; }
    .stripeForm .paymentForm .cardElement {
      width: 100%; }
      .stripeForm .paymentForm .cardElement.cardElementFocus > div {
        border-color: #767676; }
      .stripeForm .paymentForm .cardElement > div {
        border: 1px solid #eeeeee;
        transition: border-color 0.3s;
        background: #fff;
        padding: 15px 10px 15px 20px;
        border-radius: 5px; }
    .stripeForm .paymentForm .paymentButton {
      margin: 22px 0 0; }
  .stripeForm .cardError {
    margin: 10px 0 0 3px;
    font-size: 15px;
    color: #d43900; }
