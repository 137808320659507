.head {
  margin: 0 0 60px;
  text-align: center; }
  .head h1 {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 10px; }
  .head .items {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; }
    @media screen and (max-width: 765px) {
      .head .items {
        flex-direction: column;
        margin: 30px 0 0; } }
    .head .items .link {
      color: #484848;
      padding: 10px;
      margin: 0 10px;
      font-size: 14px;
      border-bottom: 2px solid transparent;
      transition: all 0.2s ease; }
      @media screen and (max-width: 765px) {
        .head .items .link {
          width: 100%;
          padding: 15px 0;
          text-align: left;
          border-bottom: 1px solid #eee; } }
      .head .items .link:hover {
        color: #ff4959;
        text-decoration: none; }
      .head .items .link.active {
        color: #ff4959;
        border-bottom: 2px solid #ff4959; }
