.termsPage {
  display: block; }
  .termsPage .termsPageInner {
    padding: 60px 0 40px;
    max-width: 700px;
    margin: 0 auto; }
    .termsPage .termsPageInner h4 {
      margin: 40px 0 20px; }
    .termsPage .termsPageInner ol {
      margin: 25px 0;
      list-style-type: lower-roman; }
