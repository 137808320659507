.component {
  padding: 80px 0;
  max-width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .component {
      padding: 40px 0; } }
  .component .errorWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    margin: 0 auto; }
    .component .errorWrap h3 {
      margin: 0 0 15px; }
  .component .certificate h1 {
    font-family: 'Tangerine', cursive;
    font-size: 70px;
    line-height: 90px;
    color: #111;
    margin: 0 0 40px; }
  .component .certificate p {
    color: #484848; }
  .component .certificate .numbers {
    margin: 40px 0; }
    .component .certificate .numbers h6 {
      margin: 0 0 10px;
      font-weight: 700;
      color: #111; }
  .component .certificate .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    padding: 50px 0 0;
    margin: 50px 0 0;
    font-size: 13px; }
