/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

.homePage {
  display: block; }
  .homePage .hero {
    position: relative;
    background-size: cover !important;
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    min-height: 700px;
    width: 100%; }
    .homePage .hero .content {
      position: relative;
      padding: 180px 0 0; }
      @media screen and (max-width: 992px) {
        .homePage .hero .content {
          padding: 140px 0 0; } }
      .homePage .hero .content .inner {
        display: flex; }
        @media screen and (max-width: 992px) {
          .homePage .hero .content .inner {
            flex-direction: column; } }
        .homePage .hero .content .inner .left {
          max-width: 600px; }
          @media screen and (max-width: 992px) {
            .homePage .hero .content .inner .left {
              text-align: center;
              margin: 0 auto; } }
          .homePage .hero .content .inner .left h1 {
            color: #fff;
            margin: 0 0 30px; }
          .homePage .hero .content .inner .left p {
            line-height: 28px;
            color: rgba(255, 255, 255, 0.8); }
          .homePage .hero .content .inner .left .actions {
            margin: 25px 0 0;
            position: relative;
            display: inline-block; }
            .homePage .hero .content .inner .left .actions button {
              z-index: 1;
              height: 70px;
              padding: 12px 50px;
              transform: none;
              text-decoration: underline; }
              .homePage .hero .content .inner .left .actions button:hover svg {
                fill: #fff; }
              .homePage .hero .content .inner .left .actions button svg {
                fill: #ff4959; }
        .homePage .hero .content .inner .right {
          position: relative; }
          @media screen and (max-width: 992px) {
            .homePage .hero .content .inner .right {
              margin: 50px 0 0;
              display: flex;
              align-items: center;
              justify-content: center; } }
          .homePage .hero .content .inner .right .preview {
            position: relative;
            max-width: 650px;
            margin: 20px -100px 0 0;
            z-index: 1; }
            @media screen and (max-width: 992px) {
              .homePage .hero .content .inner .right .preview {
                max-width: 600px;
                margin: 20px 0 0 -90px; } }
            @media screen and (max-width: 768px) {
              .homePage .hero .content .inner .right .preview {
                max-width: 100%;
                margin: 20px 0 0 -80px; } }
            @media screen and (max-width: 600px) {
              .homePage .hero .content .inner .right .preview {
                max-width: 120%;
                margin: 20px 0 0 -60px; } }
          .homePage .hero .content .inner .right .dots {
            position: absolute;
            bottom: 20px;
            right: -50px;
            max-width: 225px; }
  .homePage .featureSection {
    position: relative;
    padding: 120px 0 0; }
    @media screen and (max-width: 992px) {
      .homePage .featureSection {
        padding: 70px 0 0; } }
    .homePage .featureSection .inner {
      position: relative;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 992px) {
        .homePage .featureSection .inner {
          flex-direction: column; } }
      .homePage .featureSection .inner .left {
        max-width: 400px;
        margin: 80px 30px 0 0; }
        @media screen and (max-width: 992px) {
          .homePage .featureSection .inner .left {
            margin: 0 auto;
            text-align: center;
            max-width: 500px; } }
        .homePage .featureSection .inner .left h6 {
          color: #ff4959;
          margin: 0 0 15px; }
        .homePage .featureSection .inner .left h3 {
          margin: 0 0 15px; }
        .homePage .featureSection .inner .left p {
          font-size: 14px;
          color: #716c80; }
        .homePage .featureSection .inner .left .action {
          margin: 25px 0 0;
          position: relative;
          display: inline-block; }
          .homePage .featureSection .inner .left .action button {
            z-index: 1; }
          .homePage .featureSection .inner .left .action .dots {
            position: absolute;
            top: 15px;
            right: -55px;
            max-width: 175px; }
      .homePage .featureSection .inner .right {
        position: relative; }
        @media screen and (max-width: 992px) {
          .homePage .featureSection .inner .right {
            margin: 50px 0 0; } }
        .homePage .featureSection .inner .right .cardRow {
          display: flex;
          align-items: center; }
          @media screen and (max-width: 600px) {
            .homePage .featureSection .inner .right .cardRow {
              flex-direction: column; } }
          .homePage .featureSection .inner .right .cardRow .card {
            z-index: 1;
            margin: 15px;
            display: flex;
            flex-direction: column;
            max-width: 300px;
            width: 100%;
            padding: 35px 30px 40px;
            border-radius: 7px;
            background: #fff;
            box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1); }
            @media screen and (max-width: 992px) {
              .homePage .featureSection .inner .right .cardRow .card {
                max-width: 100%; } }
            .homePage .featureSection .inner .right .cardRow .card:first-child {
              margin: -100px 15px 15px; }
              @media screen and (max-width: 992px) {
                .homePage .featureSection .inner .right .cardRow .card:first-child {
                  margin: 15px; } }
            .homePage .featureSection .inner .right .cardRow .card .icon {
              width: 50px;
              height: 50px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center; }
              .homePage .featureSection .inner .right .cardRow .card .icon.red {
                background: #ffecee; }
              .homePage .featureSection .inner .right .cardRow .card .icon.green {
                background: #e7fbf0; }
              .homePage .featureSection .inner .right .cardRow .card .icon.blue {
                background: #e7f3ff; }
              .homePage .featureSection .inner .right .cardRow .card .icon.orange {
                background: #fef2ed; }
            .homePage .featureSection .inner .right .cardRow .card h5 {
              margin: 15px 0 20px; }
            .homePage .featureSection .inner .right .cardRow .card p {
              font-size: 14px;
              color: #716c80;
              margin: 0; }
    .homePage .featureSection .oval {
      position: absolute;
      right: 0;
      top: -100px;
      max-width: 650px; }
  .homePage .pointsSection {
    position: relative;
    padding: 80px 0 0; }
    .homePage .pointsSection .oval {
      position: absolute;
      left: 0;
      top: -100px;
      max-width: 650px; }
    .homePage .pointsSection .inner {
      position: relative;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .homePage .pointsSection .inner {
          flex-direction: column-reverse; } }
      .homePage .pointsSection .inner .left {
        position: relative; }
        @media screen and (max-width: 992px) {
          .homePage .pointsSection .inner .left {
            margin: 60px 40px 0 0; } }
        @media screen and (max-width: 992px) {
          .homePage .pointsSection .inner .left {
            margin: 60px 0 0; } }
        .homePage .pointsSection .inner .left .featured {
          z-index: 1;
          position: relative;
          max-width: 350px;
          background: #fff;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1); }
          @media screen and (max-width: 768px) {
            .homePage .pointsSection .inner .left .featured {
              max-width: 100%; } }
          .homePage .pointsSection .inner .left .featured .image {
            width: 100%;
            height: 200px; }
            .homePage .pointsSection .inner .left .featured .image img {
              width: 100%;
              height: 100%;
              max-width: 100%;
              object-fit: cover; }
          .homePage .pointsSection .inner .left .featured .content {
            display: inline-block;
            padding: 35px 30px; }
            .homePage .pointsSection .inner .left .featured .content h5 {
              margin: 0 0 25px; }
            .homePage .pointsSection .inner .left .featured .content button {
              width: auto; }
        .homePage .pointsSection .inner .left .dots {
          max-width: 200px;
          position: absolute;
          bottom: -50px;
          right: -50px; }
      .homePage .pointsSection .inner .right {
        max-width: 400px;
        margin: 20px 30px 0 0; }
        @media screen and (max-width: 768px) {
          .homePage .pointsSection .inner .right {
            max-width: 100%;
            margin: 20px 0 0; } }
        .homePage .pointsSection .inner .right h6 {
          color: #ff4959;
          margin: 0 0 15px; }
        .homePage .pointsSection .inner .right h3 {
          margin: 0 0 15px; }
        .homePage .pointsSection .inner .right p {
          font-size: 14px;
          color: #716c80; }
        .homePage .pointsSection .inner .right .points {
          margin: 30px 0 0;
          border-top: 1px solid #eee;
          display: flex;
          flex-flow: wrap; }
          @media screen and (max-width: 600px) {
            .homePage .pointsSection .inner .right .points {
              flex-direction: column; } }
          .homePage .pointsSection .inner .right .points .point {
            display: flex;
            align-items: center;
            width: 50%;
            padding: 30px 0 0; }
            @media screen and (max-width: 600px) {
              .homePage .pointsSection .inner .right .points .point {
                width: 100%; } }
            .homePage .pointsSection .inner .right .points .point .title {
              margin: 0 20px 0 5px;
              font-weight: 700;
              font-size: 14px;
              color: #3d3d3d; }
  .homePage .aboutSection {
    position: relative;
    padding: 120px 0; }
    .homePage .aboutSection .inner {
      position: relative;
      display: flex; }
      @media screen and (max-width: 600px) {
        .homePage .aboutSection .inner {
          flex-direction: column; } }
      .homePage .aboutSection .inner .left {
        max-width: 400px;
        margin: 80px 130px 0 0; }
        @media screen and (max-width: 992px) {
          .homePage .aboutSection .inner .left {
            margin: 40px 40px 0 0; } }
        @media screen and (max-width: 600px) {
          .homePage .aboutSection .inner .left {
            max-width: 100%;
            margin: 40px 0 0; } }
        .homePage .aboutSection .inner .left h6 {
          color: #ff4959;
          margin: 0 0 15px; }
        .homePage .aboutSection .inner .left h3 {
          margin: 0 0 15px; }
        .homePage .aboutSection .inner .left p {
          font-size: 14px;
          color: #716c80; }
        .homePage .aboutSection .inner .left .action {
          margin: 25px 0 0;
          position: relative;
          display: inline-block; }
          .homePage .aboutSection .inner .left .action button {
            z-index: 1; }
          .homePage .aboutSection .inner .left .action .dots {
            position: absolute;
            top: 15px;
            right: -55px;
            max-width: 175px; }
      .homePage .aboutSection .inner .right {
        position: relative;
        max-width: 300px; }
        @media screen and (max-width: 992px) {
          .homePage .aboutSection .inner .right {
            margin: 80px 0 0; } }
        @media screen and (max-width: 600px) {
          .homePage .aboutSection .inner .right {
            margin: 80px auto 0; } }
        .homePage .aboutSection .inner .right .primary {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 350px;
          height: 450px;
          width: 100%;
          position: relative; }
          .homePage .aboutSection .inner .right .primary .play {
            background: #ff4959;
            z-index: 3;
            position: absolute;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 75px;
            border-radius: 150px; }
            .homePage .aboutSection .inner .right .primary .play:after {
              position: absolute;
              content: '';
              display: block;
              background: rgba(255, 73, 89, 0.25);
              width: 100px;
              height: 100px;
              border-radius: 150px; }
            .homePage .aboutSection .inner .right .primary .play svg {
              z-index: 1; }
          .homePage .aboutSection .inner .right .primary .imagePrimary {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1); }
        .homePage .aboutSection .inner .right .imageSecondary {
          position: absolute;
          z-index: 1;
          top: -75px;
          right: -150px;
          max-width: 200px;
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1); }
        .homePage .aboutSection .inner .right .dots {
          max-width: 175px;
          position: absolute;
          right: -50px;
          bottom: -50px; }
    .homePage .aboutSection .oval {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 650px; }
  .homePage .whySection {
    margin: 0;
    position: relative;
    background-size: cover !important;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    padding: 0 0 400px;
    width: 100%; }
    .homePage .whySection .whyInner {
      position: relative; }
      .homePage .whySection .whyInner .head {
        text-align: center; }
        .homePage .whySection .whyInner .head h6 {
          color: #ff4959;
          margin: 0 0 10px; }
        .homePage .whySection .whyInner .head h3 {
          margin: 0 auto 15px;
          max-width: 450px; }
      .homePage .whySection .whyInner .boxes {
        display: flex;
        margin: 50px -15px;
        flex-flow: wrap; }
        @media screen and (max-width: 992px) {
          .homePage .whySection .whyInner .boxes {
            margin: 30px -15px; } }
        .homePage .whySection .whyInner .boxes .box {
          width: calc(50% - 30px);
          position: relative;
          margin: 15px;
          padding: 40px 50px;
          background: #fff;
          border-radius: 3px;
          box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: center; }
          @media screen and (max-width: 992px) {
            .homePage .whySection .whyInner .boxes .box {
              width: 100%;
              padding: 40px 30px; } }
          .homePage .whySection .whyInner .boxes .box .number {
            position: absolute;
            top: -15px;
            left: -15px;
            border-radius: 50px;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            background: #ff4959;
            font-weight: 500; }
          .homePage .whySection .whyInner .boxes .box .left {
            margin: 0 20px 0 0; }
          .homePage .whySection .whyInner .boxes .box .right h5 {
            margin: 0 0 10px; }
          .homePage .whySection .whyInner .boxes .box .right p {
            margin: 0;
            color: #645f7b;
            font-size: 14px; }
  .homePage .customerSection .customerInner {
    position: relative;
    margin: -350px 0 0; }
    .homePage .customerSection .customerInner .head {
      text-align: center; }
      .homePage .customerSection .customerInner .head h6 {
        color: #ff4959;
        margin: 0 0 10px; }
      .homePage .customerSection .customerInner .head h3 {
        max-width: 450px;
        margin: 0 auto 15px; }
    .homePage .customerSection .customerInner .boxes {
      display: flex;
      margin: 40px -15px;
      flex-flow: wrap; }
      @media screen and (max-width: 992px) {
        .homePage .customerSection .customerInner .boxes {
          margin: 20px -15px; } }
      .homePage .customerSection .customerInner .boxes .box {
        width: calc(33.333333% - 30px);
        position: relative;
        margin: 15px;
        padding: 40px 40px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 50px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        flex-direction: column; }
        @media screen and (max-width: 992px) {
          .homePage .customerSection .customerInner .boxes .box {
            width: calc(100% - 30px); } }
        .homePage .customerSection .customerInner .boxes .box .image img {
          max-width: 80px; }
        .homePage .customerSection .customerInner .boxes .box p {
          margin: 10px 0 30px; }
        .homePage .customerSection .customerInner .boxes .box .bottom .name {
          font-weight: 500;
          color: #484848; }
        .homePage .customerSection .customerInner .boxes .box .bottom .company {
          font-size: 14px; }
  .homePage .homePageInner {
    padding: 120px 0; }
    .homePage .homePageInner h1 {
      margin: 0 0 30px; }
