/**
 * Export variables to use in JS
 */
:export {
  /*
     * Base
     */
  basePrimary: #ff4959;
  basePrimaryAccent: #e54150;
  basePrimaryOpacity30: #a1c1ff;
  baseWhite: #fff;
  baseDark800: #222222;
  baseDark900: #0e0e0e;
  baseDarkTrans200: #333333;
  /*
     * Text
     */
  textLight: #717171;
  textBase: #484848;
  textDark: #222222;
  /**
     * Backgrounds
     */
  backgroundLight: #fafafa;
  backgroundLighten: #f5f5f5;
  /**
     * Borders
     */
  borderLight: #eeeeee;
  borderLight200: #f7f7f7;
  borderRegular: #dddddd;
  borderDark: #767676;
  /**
     * States
     */
  stateErrorLight: #fff6f6;
  stateErrorRegular: #d43900; }

/**
 * Export variables to use in JS
 */
:export {
  /*
     * Fonts
     */
  fontPrimary: DM Sans, sans-serif;
  /**
     * Weights
     */
  weightRegular: 400;
  weightMedium: 500;
  weightBold: 700; }

#root {
  overflow: hidden; }

.overflowHidden {
  overflow: hidden; }

@media print {
  header,
  footer {
    display: none; } }

@page {
  size: auto;
  margin: 0; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 500;
  color: #484848;
  margin: 0; }

h1,
.h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px; }
  @media screen and (max-width: 992px) {
    h1,
    .h1 {
      font-size: 36px;
      line-height: 46px; } }

h2,
.h2 {
  font-size: 36px;
  line-height: 46px; }

h3,
.h3 {
  font-size: 28px;
  line-height: 38px; }

h4,
.h4 {
  font-size: 24px;
  line-height: 34px; }

h5,
.h5 {
  font-size: 18px;
  line-height: 28px; }

h6,
.h6 {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 500; }

button {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
  color: #484848; }

input {
  font-family: DM Sans, sans-serif;
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
  font-weight: 400;
  padding: 12px 15px;
  width: 100%;
  border: 0;
  outline: 0; }

::placeholder {
  font-family: DM Sans, sans-serif;
  font-size: inherit;
  line-height: inherit;
  color: #717171; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }
